import React from 'react'

const PageNotFound = () => {
  return (
    <div className='d-flex align-items-center fw-bolder fs-2 justify-content-center w-100' style={{minHeight:'70vh'}}>
      This page is not available.
    </div>
  )
}

export default PageNotFound