import axios from 'axios'
import React, { useEffect, useState } from 'react'

const AllMails = () => {
    const [id, setId] = useState(0)
    const [data, setData] = useState([])
    const [query, setQuery] = useState([])
    useEffect(() => {
        axios.get('https://api.legalbrother.in/api/all_mails')
            .then(res => {
                setData(res.data.data)
                // console.log(res.data.data)
                if (res.data.success && Array.isArray(res.data.data)) {
                    const extractedData = res.data.data.map(item => ({
                        id: item.id,
                        query: item.query,
                        name: item.name
                    })); // Extracting both ID and query

                    setQuery(extractedData);
                } else {
                    console.error("Invalid response format:", res.data);
                }
            })
            .catch(err => {
                console.error("Error fetching data:", err);
            });
    }, []);


    return (
        <div className='container vh-100'>
            <div className='row '>
                <div className='col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-3 pt-3' >
                    <div className='w-100 py-2 mb-2 shadow-sm justify-content-center align-items-center btn bg-light text-primary fw-bold border rounded-0'>User</div>
                    <div className='card border-0 shadow-sm' style={{ overflow: 'auto', maxHeight: '70vh' }}>
                        {query &&
                            query.map((data, idx) => (
                                <div onClick={e => setId(data.id)} className={`${data.id == id ? 'btn-info ps-5 fw-bold' : ' ps-3'} py-2`} style={{ cursor: 'pointer', transition: '0.2s ease-in-out' }}>{data.name}</div>
                            ))
                        }
                    </div>
                </div>
                <div className='col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 mt-3 pt-3 ' style={{ minHeight: '70vh' }}>
                    <div className='w-100 py-2 mb-2 shadow-sm justify-content-center align-items-center btn bg-light text-primary fw-bold border rounded-0'>Message</div>
                    {id == 0 && <div className='w-100 d-flex justify-content-center mt-4'>Select Any of the given contact.</div>}
                    {data &&
                        data.map((data, idx) => (
                            <div className='shadow p-4  rounded-1' style={{ display: `${data.id == id ? 'block' : 'none'}` }}>
                                {data.id == id && <div style={{ transition: '0.2s ease-in-out' }}>
                                    <div className='border bg-light text-dark w-100 d-flex align-items-center justify-content-between'>
                                        <div className='p-1 fw-bold'>
                                            Date : &nbsp;&nbsp;&nbsp;
                                            {/* {data.createdAt.split('T')[0]}  */}
                                            <b className='text-uppercase'>
                                                {new Date(data.createdAt.split('T')[0]).toLocaleDateString('en-GB', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric'
                                                }).replace(',', '-')}
                                            </b>

                                        </div>
                                        <div className='p-1 fw-bold'>
                                            Time : &nbsp;&nbsp;&nbsp;
                                            {/* {data.createdAt.split('T')[1].split('.000')[0]} */}
                                            <b className='text-uppercase'>{data.time}</b>
                                        </div>
                                    </div>
                                    <table className='mt-3'>
                                        <thead className='d-none'>
                                            <th>
                                                User Contact
                                            </th>
                                            <th>
                                                &nbsp;&nbsp;&nbsp;Information
                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Message from</td>
                                                <td><strong className=''>:&nbsp;&nbsp;&nbsp;{data.name}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Phone Number</td>
                                                <td><strong className=''>:&nbsp;&nbsp;&nbsp;{data.mobile}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Email ID</td>
                                                <td><strong className=''>:&nbsp;&nbsp;&nbsp;{data.email}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                                    <div className='mt-5 border p-2 bg-light' style={{ minHeight: '200px' }}>
                                        <b>Query / Message :-</b><hr />
                                        {data.query}.
                                    </div>
                                </div>}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AllMails